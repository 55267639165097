@import './../components/Banner/styles/mobile';
@import '../components/Projects/styles/mobile';
@import '../components/Numbers/styles/mobile';
@import './../components/Us/styles/mobile';
@import './../components/Contact/styles/mobile';

.switch {
  display: flex;
  flex-flow: column;
}

@media (min-width: 780px) {
  @import './../components/Banner/styles/desktop';
  @import '../components/Projects/styles/desktop';
  @import '../components/Numbers/styles/desktop';
  @import './../components/Us/styles/desktop';
  @import './../components/Contact/styles/desktop';

  .switch {
    flex-flow: column-reverse;
  }
}
