@import "./default";

.home__banner {
  height: 550px;
  .home__slider {
    max-width: 100%;

    .slider__slide {
      height: 550px;
      overflow: hidden;

      figure {
        height: 550px;

        .slider__filter {
          height: 550px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .slider__content {
        padding: 56px;

        h2 {
          font-size: 32px;
        }

        .slider__button {
          padding-top: 8px;
          font-size: 24px;
          font-weight: 300;
        }
      }
    }
  }
}
