.ourus {
  .ourus__container {
    max-width: $max-width;
    margin: 0 auto;
  }

  .ourus__header {
    background: $primary-color;
    text-align: center;
    color: white;
    padding: 32px 0;
    font-size: 24px;
  }

  .ourus__presentation {
    max-width: $max-width;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin: 24px auto 24px auto;

    img {
      height: auto;
    }

    .presentation__text-container {
      padding: 0;
      text-align: left;
    }
  }

  .ourus__team {
    max-width: $max-width;
    margin-bottom: 48px;

    ol {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
    }
  }

  .ourus__history {
    margin: 0 auto;
    max-width: $max-width;
    grid-template-columns: 1fr 1fr;
  }

  .detail__container {
    grid-template-columns: 1fr;

    p {
      font-size: 18px;
      line-height: 32px;
    }

    img {
      height: 500px;
    }
  }
}