@import "./default";

.contact {

  .container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 62px 0;

    h2 {
      font-size: 32px;
    }

    .networks {
      i {
        font-size: 32px;
      }

      li {
        margin: 0 62px;
      }
    }

    .separator {
      margin-top: 64px;
      width: 400px;
    }

    .data-contact {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;

      i {
        font-size: 32px;
      }

      div  {
        display: flex;
        justify-content: center;
      }

      li {
        display: flex;
        width: 100%;

        h3 {
         font-size: 18px;
          width: 300px;
        }
      }
    }
  }
}
