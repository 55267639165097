@import "./default";

footer {
  padding: 12px 0;

  .footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width-mobile;
    margin: 0 auto;
  }

  img {
    height: 48px;
  }

  h2, h3, h4 {
    font-weight: 800;
    text-align: right;
  }

  h3 {
    padding: 4px 0;
    font-size: 14px;
  }
}
