@import "./default";

.us {
  max-width: $max-width-mobile;
  margin: 0 auto;
  color: black;
  text-align: center;
  padding: 24px 0;

  h2 {
    font-size: 20px;
    font-weight: 800;
  }

  ol {
    li {
      margin-top: 24px;
      height: 365px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;

      img {
        object-fit: cover;
        height: 365px;
      }

      div  {
        position: absolute;
        background: $primary-color;
        padding: 12px;
        font-weight: 300;
        color: white;
        text-transform: uppercase;
        bottom: 24px;
        width: 200px;
      }
    }
  }
}
