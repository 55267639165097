header {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;

  .header__nav {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header__fix-space {
  height: 80px;
}

a {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: $primary-color;
  }
}

.header__link {
  color: #333;

  &:hover {
    color: #333 !important;
  }
}
