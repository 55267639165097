@import "./default";

.projects {
  margin-top: -5px;
  .container {
    max-width: $max-width-mobile;
    margin: 0 auto;

    section {
      display: none;
    }

    ol {
      li {
        position: relative;
        margin: 32px 0;
        height: 345px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          position: absolute;
          width: 100%;
        }

        div {
          bottom: 0;
          position: absolute;
          background: $primary-color;
          text-transform: uppercase;
          font-weight: 300;
          padding: 12px;
          margin-bottom: 32px;
          min-width: 120px;
          text-align: center;
          color: white !important;
        }
      }
    }
  }
}
