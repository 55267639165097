@import "./default";

.us {
  max-width: $max-width;
  padding: 64px;

  h2 {
    font-size: 32px;
  }

  ol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;

    li {
      margin-top: 80px;
      height: 400px;

      img {
        height: 400px;
      }
    }
  }
}
