$max-width: 1100px;
$max-width-mobile: calc(100% - 32px);
$mdu: 8px;

// Palette
$primary-color: #AB2328;
$primary-color-hover: #333;
$secondary-color: #333;
$bg-color-1: black;
$bg-color-2: #0b0c0b;
$font-primary-color: white;
$font-secondary-color: #0a525a;

// Fonts types
$primary-font: 'Lato';
