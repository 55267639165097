.history {

  .history__header {
    background: $primary-color;
    text-align: center;
    color: white;
    padding: 24px 0;
    font-size: 24px;
    text-transform: uppercase;
  }

  .history__container {
    margin: 0 auto;
    max-width: $max-width-mobile;
    padding: 32px 0;

    .container__text {
      color: black;
      line-height: 24px;
      font-weight: 300;
      padding-bottom: 32px;
    }

    .container__publications {
      display: grid;
      grid-template-columns: 1fr;

      article {
        width: 100%;
        height: 344px;
        margin-top: 24px;
        background: #f1f1f1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .item__container {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
        }

        img {
          width: 100%;
          height: 344px;
          object-fit: cover;
        }
      }
    }
  }

  .item__skeleton {
    height: 344px;
    transform: none;
    margin-top: 0 !important;
  }
}