@import "./default";

.contact {
  background: url("./../../../../../assets/images/contact.jpg") center;
  background-size: cover;

  .container {
    max-width: $max-width-mobile;
    margin: 0 auto;
    color: black;
    text-align: center;
    padding: 25px 0;

    i, a {
      color: black;
      font-size: 24px;
    }

    h2 {
      font-weight: 800;
      font-size: 18px;
    }

    .networks {
      margin-top: 55px;
      display: flex;
      justify-content: center;

      li {
        margin: 0 20px;
      }
    }

    .separator {
      width: 150px;
      background: black;
      height: 1px;
      margin: 0 auto;
      margin-top: 24px;
    }

    .data-contact {
      padding-top: 10px;
      width: 80%;
      margin: 0 auto;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 12px;

        i {
          width: 50px;
        }

        h3 {
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }
}
