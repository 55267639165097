.items {
  max-width: $max-width-mobile;
  margin: 0 auto;

  .item__skeleton {
    height: 344px;
    transform: none;
    margin-top: 0 !important;
  }

  article {
    width: 100%;
    height: 344px;
    margin-top: 24px;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;

    .item__container {
      display: none;
    }

    img {
      width: 100%;
      height: 344px;
      object-fit: cover;
    }
  }
}

.items__header {
  text-align: center;
  height: 56px;
  line-height: 56px;
  background: $primary-color;

  h1 {
    font-size: 18px;
  }
}

.items__filters {
  text-align: center;
  color: $primary-color;
  margin-top: 24px;

  li {
    display: inline-block;
    cursor: pointer;
    margin: 0 8px;
    border: 1px solid $primary-color;
    padding: 8px 12px;
    box-sizing: border-box;

    &:hover {
      background: $primary-color;
      color: white;
    }
  }

  .filter__selected {
    background: $primary-color;
    color: white;
  }
}