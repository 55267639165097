.ourus {
  .ourus__header {
    background: $primary-color;
    text-align: center;
    color: white;
    padding: 24px 0;
    font-size: 24px;
    text-transform: uppercase;
  }

  .ourus__presentation {

    img {
      width: 100%;
      object-fit: cover;
      height: 250px;
    }

    .presentation__text-container {
      padding: 24px;
      color: black;
      font-weight: 300;
      line-height: 24px;
      text-align: center;

      p {
        text-align: left;
      }

    }

    .button-default {
      padding: 8px 24px;
      margin-top: 24px;
    }
  }

  .ourus__team {
    max-width: $max-width-mobile;
    margin: 0 auto;

    ol {
      width: 100%;

      li {
        margin-top: 24px;
        height: 365px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        position: relative;

        img {
          object-fit: cover;
          height: 365px;
          width: 100%;
        }

        div  {
          position: absolute;
          background: $primary-color;
          padding: 12px;
          font-weight: 300;
          color: white;
          text-transform: uppercase;
          bottom: 24px;
          width: 200px;
        }
      }
    }
  }

  .ourus__history {
    display: grid;
    margin-top: 24px;

    .history__container {
      position: relative;

      section {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(white, 0.95);
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          color: black;
          text-align: center;

          p {
            margin-top: 8px;
            font-weight: 600;
          }

          .ourus__icon {
            height: 60px;
          }
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .detail {
    h2 {
      font-weight: 600;
    }
  }
}