.unavailable {
  display: flex;
  align-items: center;
  height: 80vh;

  .unavailable__container {
    text-align: center;
    width: 100%;

    i {
      font-size: 68px;
    }

    p {
      padding-top: 24px;
      font-weight: 300;
      font-size: 18px;
    }

    a {
      text-decoration: none;
      color: $primary-color;
      position: relative;
      top: 8px;
    }
  }
}
