.history {
  .history__header {
    background: $primary-color;
    text-align: center;
    color: white;
    padding: 24px 0;
    font-size: 24px;
    text-transform: uppercase;
  }

  .history__container {
    max-width: $max-width;

    .container__publications {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;

      article {
        position: relative;

        &:hover {
          .item__container {
            opacity: 1;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(black, 0.4);
            width: 100%;
            height: 100%;
            color: white !important;

            h2 {
              font-size: 32px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .item__skeleton {
    height: 344px;
    transform: none;
    margin-top: 0 !important;
  }
}