.vip {
  .vip--desktop {
    display: block;

    .vip__container {
      max-width: $max-width;
      margin: 0 auto;
    }

    .container__head {
      padding: 32px 0;
      display: flex;
      justify-content: space-between;
    }

    .vip__slider {
      max-width: 68%;
      width: 100%;

      //img {
      //  width: auto !important;
      //  max-width: 100%;
      //  height: 350px;
      //}

      .slick-track {
        height: 400px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        cursor: pointer;

      }
      .slick-dots {
        bottom: 10px;
        z-index: 99999999;
      }

      .slick-dots li.slick-active button:before {
        color:white !important;
      }
      .slick-dots li button:before {
        color:white !important;
      }
    }

    .vip__body {
      color: black;
      max-width: 28%;
      width: 100%;

      h2 {
        font-size: 24px;
      }

      p {
        margin-top: 12px;
        font-weight: 300;
        font-size: 18px;
      }

    }



    .vip__header {
      background: $primary-color;
      text-align: center;
      color: white;
      padding: 38px 0;
      font-size: 32px;
    }
  }

  .vip--mobile {
    display: none;
  }


  .container__carrusel {

    .slick-slider {

      .slick-slide {
        img {
          width: 260px !important;
          height: 180px !important;
          object-fit: cover;
          cursor: pointer;
        }
      }

      .slick-arrow:before {
        color: black !important;
      }
    }

  }

  .container__text {
    color: black;
    padding: 32px 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
}