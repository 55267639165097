.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: $max-width;
  gap: 48px;
  padding: 48px 0;

  .item__skeleton {
    height: 344px;
    transform: none;
    margin-top: 24px;
  }

  article {
    margin-top: 0;
    position: relative;

    &:hover {
      .item__container {
        opacity: 1;
        transition: 0.4s;
      }
    }

    .item__container {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(black, 0.7);
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: 0.4s;

      h2 {
        font-size: 18px;
        color: white !important;
      }
    }
  }
}

.items__header {
  height: 100px;
  line-height: 100px;

  h1 {
    font-size: 24px;
  }
}

.items__developments {
  .item__skeleton:first-child {
    grid-column: 1 / 4;
  }
  a:first-child {
    grid-column: 1 / 4;

    article {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }

      .item__container {
        background: rgba(black, 0.2);
        opacity: 1;
        justify-content: end;
        align-items: end;
        flex-direction: column-reverse;
        padding: 32px;
        box-sizing: border-box;

        h2 {
          font-size: 32px;
          text-transform: uppercase;
        }
      }
    }
  }
}