@import "./default";

.home__banner {
  background: black;
  height: 366px;

  .home__slider {
    max-width: 100%;

    .slider__slide {
      display: block;
      height: 366px;
      position: relative;

      figure {
        height: 366px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .slider__filter {
          height: 366px;
        }

        img {
          height: 366px;
        }
      }

      .slider__content {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column-reverse;
        padding: 32px;

        h2 {
          font-size: 18px;
          text-transform: uppercase;
        }

        .slider__button {
          color: white;
          padding-top: 4px;
          font-size: 14px;
          opacity: .8;
        }
      }
    }
  }
}
