@import "./default";

footer {
  .footer__container {
    max-width: $max-width;
  }

  img {
    height: 62px;
  }

  h3 {
    padding: 4px 0;
    font-size: 16px;
  }
}
