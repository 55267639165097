@import "./default";

.numbers {
  background: $primary-color;

  .container {
    max-width: $max-width-mobile;
    margin: 0 auto;
    padding: 40px 0;
  }

  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }

  ol {
    width: 200px;
    margin: 0 auto;

    li {
      margin-top: 30px;
      display: flex;
      align-items: center;

      svg {
        fill: white;
        width: 28px;
        height: 30px;
      }

      div {
        margin-left: 12px;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
}
