@import "./default";

.projects {
  background: $primary-color;

  .container {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    section {
      display: flex;
    }
  }

  section {
    text-align: center;
    display: flex;
    align-items: center;

    h2 {
      text-transform: uppercase;
      font-size: 24px;
      margin-top: 12px;
      font-weight: 800;
    }

    svg {
      fill: white
    }
  }

  ol {
    margin: 0;
    display: flex;

    li {
      position: relative;
      margin: 32px 0;
      margin-left: 24px !important;
      height: 280px !important;
      width: 250px !important;

      img {
        width: auto;
        height: 100%;
      }

      div {
        padding: 9px !important;
        font-size: 14px;
      }
    }
  }
}
