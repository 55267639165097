@import "./default";

header {
  height: 70px;

  .header__nav {
    width: $max-width;
  }

  .header__logo {
    height: 63px;
  }

  .header__menu {
    font-size: 16px;
    text-transform: uppercase;
    color: $primary-color;

    li {
      display: inline-block;
      margin-left: 24px;
      font-weight: 300;
      height: 70px;
      line-height: 70px;
      position: relative;

      &:hover {
        font-weight: 400 !important;
        z-index: 9999;

        ul {
          display: block;
        }
      }

      ul {
        display: none;
        position: absolute;
        background: white;
        padding: 12px;
        width: 80px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        li {
          height: auto !important;
          font-size: 14px;
          line-height: normal !important;
          display: block;
          margin-top: 10px;
          margin-left: 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
