.whatsapp__widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #26d367;
  height: 60px;
  width: 60px;
  text-align: center;
  border-radius: 100px;
  z-index: 99;

  i {
    color: #fff;
    font-size: 30px;
    line-height: 60px;
  }
}
