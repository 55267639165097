// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:200,300,400,500,600,700&display=swap');

@import 'clear-styles';
@import "variables";

// Components
@import './../../components/Header/styles/index';
@import './../../components/Footer/styles/index';
@import './../../components/Slider/styles/index';
@import "./../../components/WhatsApp/index";

// Pages
@import './../../pages/home/styles/index';
@import './../../pages/unavailable/styles/index';
@import './../../pages/items/styles/index';
@import './../../pages/vip/styles/index';
@import './../../pages/ourus/styles/index';
@import './../../pages/history/styles/index';

// Dependeneces
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


// Overrides
body {
  font-family: $primary-font, sans-serif;
  color: $font-primary-color;
  background: white;
}

a {
  &:hover {
    color: $primary-color !important;
  }
}

.svg-loaders-svg {
  stroke: $primary-color;
  width: 100%;
}

.button-default {
  background: $primary-color;
  display: inline-block;
  padding: 14px 15px;
  box-sizing:  border-box;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: white;

  &:hover {
    opacity: 0.9;
  }
}

.subtitle {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  color: $secondary-color;
}

@media (min-width: 780px) {

  .subtitle {
    //font-size: 36px;
  }
}

