@import "./default";

.numbers {
  .container {
    max-width: $max-width;
    padding: 52px 0;
  }

  ol {
    width: 100%;
    display: flex;
    justify-content: space-between;

    li {
      margin: 80px 0 20px;

      div {
        font-size: 20px;
        line-height: 24px;
      }
      svg {
        height: 62px;
        width: 65px;
      }
    }
  }

  h2 {
    font-size: 32px;
  }

}
