.vip {
  .vip--mobile {
    .slick-track {
      height: calc(100% - 80px) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background: black;

    }
    .slick-dots {
      bottom: 10px;
      z-index: 99999999;
    }

    .slick-dots li.slick-active button:before {
      color:white !important;
    }
    .slick-dots li button:before {
      color:white !important;
    }

    .vip__header {
      background: $primary-color;
      text-align: center;
      color: white;
      padding: 24px 0;
      font-size: 24px;
    }

    .vip__descripcion {
      max-width: $max-width-mobile;
      margin: 0 auto;
      color: black;
      padding: 24px 0;

      h3 {
        line-height: 28px;
        font-weight: 300 !important;
        font-size: 18px;
      }

      hr {
        border: 0;
        height: 1px;
        background: black;
        opacity: .1;
        margin: 24px 0
      }

      p {
        line-height: 24px;
        font-weight: 300 !important;
        font-size: 16px;
      }
    }
  }

  .vip--desktop {
    display: none;
  }
}