@import "./default";

header {
  .header__nav {
    width: $max-width-mobile;
  }

  .header__logo {
    height: 36px;
    margin-top: 4px;
  }
}

.sidebar__list {
  padding: 32px;

  li {
    list-style: none;
    margin-top: 24px;
    font-size: 24px;

    ul {
     li {
       font-size: 24px;
       font-weight: 300;
       margin-top: 10px;
     }
    }

    &:first-child {
      margin-top: 20px;
    }
  }
}